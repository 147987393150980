
  import {
    defineComponent,
    ref,
    inject,
    onActivated,
    nextTick,
    onDeactivated,
    onUnmounted,
    watch,
    toRaw,
    computed,
  } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import api from "@/api/axios";
  import dateFormat from "@/utils/dateFormat";
  import Loading from "@/components/UI/Loading/src/Loading.vue";
  import PostTopicBtn from "@/components/common/PostTopicBtn.vue";
  import Preview from "@/components/UI/Preview";
  import qs from "qs";
  import PersonalMine from "@/components/personal/Mine.vue";
  import Letter from "@/components/personal/Letter.vue";

  import wechatLogin from "@/api/wechatLogin";
  import usePageScroll, { PropsType } from "@/hooks/usePersonalScroll";
  import $ from "jquery";
  export default defineComponent({
    name: "Personal",
    components: {
      Loading,
      PostTopicBtn,
      Preview,
      PersonalMine,
      Letter,
    },
    data(){
      return {
        showModal: false,
        showSearch: false,
        searchText:'',
        //选择的用户组
        checkedUer:[],
        userIdList:[],
        userIds:''
      }
    },
    methods:{
      showRight(){
        this.showModal=true;
      },
      searchUser(){
        console.log(this.searchText)
        if(this.searchText==''){
          Toast({
            type: "error",
            title: '请输入好友昵称或手机号码',
          });
          return false;
        }
        this.loading=true;
        api.get("/M/Personal/findFriendByNickNameOrMobile?keyword="+this.searchText+"&type=1")
                .then((res) => {
                  console.log(res)
                  this.loading=false
                  if (res.data.success) {
                    this.userList = res.data.obj;
                    if (this.userList.length == 0) {
                      Toast({
                        type: "error",
                        title: '昵称或手机号为【'+this.searchText+'】的好友不存在！',
                      });
                    }
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  this.loading=false
                  console.log("err");
                });
      },
      closeModal(){
        this.showModal=false
      },
      DouserGive(){
        this.userIds='';
        const userIdList=toRaw(this.userIdList);
        userIdList.map((item: any)=>{
          if( this.userIds==''){
            this.userIds+=item
          }else {
            this.userIds=this.userIds+','+item
          }
        });
        console.log(this.userIds)
        api.get("/M/Personal/focusFriends?userIds="+this.userIds)
                .then((res) => {
                  console.log(res)
                  if (res.data.success) {
                    Toast({
                      type: "success",
                      title: res.data.msg,
                    });
                    location.reload()
                  } else {
                    Toast({
                      type: "success",
                      title: res.data.msg,
                    });
                  }
                  this.showModal=false;
                })
                .catch((err) => {
                  console.log("err");
                  this.showModal=false;
                });

      }
    },
    setup() {
      const route = useRoute();
      const id = ref(route.query.uid);

      const userData = ref(null);
      const menu = ["我的", "关注", "粉丝", "私信"];
      const currentMenu = ref("我的");
      const focusUserData = ref(null);
      const subUserData = ref(null);
      const letterUserData = ref(null);
      const loading = ref(false);
      let clearScrollFn: () => void;
      let clearScrollFc: () => void;
      let activeScroll: () => void;
      let updateShow: (param: boolean) => void;
      /**
       * 组装从【我的】接口信息中获取到的交流区信息
       */
      function mapTopicData(data: any, user: any) {
        const topic = data.topic
                ? [data.topic]
                : [
                  {
                    id: data.mTopicId,
                    user: user,
                    title: data.mTitle,
                    pics: data.pics,
                    summary: data.msummary,
                    createTime: data.mlastPostTime,
                    clickCount: data.mviews,
                    likeUser: data.mList,
                    isVerify: data.isVerify,
                  },
                ];
        return {
          name: data.mForumName,
          data: topic,
        };
      }
      /**
       * 组装从【我的】接口信息中获取到的课堂信息
       */
      function mapCourseData(data: any) {
        return {
          name: data.mForumName,
          data: [
            {
              id: data.mCourseId,
              courseType: data.mType,
              coursePicUrl: data.mpicUrl,
              courseName: data.mTitle,
              realName: data.mRealName,
              liveStartTime: data.mlastPostTime,
              isVerify: data.isVerify,
              createUser: data.createUser,
            },
          ],
        };
      }
      /**
       * 组装从【我的】接口信息中获取到的信息
       */
      function filterFc(user: any) {
        return function (item: any) {
          switch (item.mForumName) {
            case "课堂":
              return mapCourseData(item);
            case "交流区":
              return mapTopicData(item, user);
            case "问答":
              return mapTopicData(item, user);
            default:
              return item;
          }
        };
      }
      /**
       * 获取我的【我的】
       */
      function getPersonal() {
        loading.value = true;
        axios
                .get(`/M/Personal/${id.value}`)
                .then((res) => {
                  if (res.data.success) {
                    userData.value = null;
                    userData.value = res.data.obj;
                    // console.log('userData.value',userData.value)

                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }

                  loading.value = false;
                  return res;
                })
                .then((res) => {
                  const {
                    result,
                    clear,
                    updateShowItem,
                    clearScroll,
                    active,
                    empty,
                  } = usePageScroll(
                          {
                            url: `/M/Personal/personalJson/${id.value}`,
                            filterFunc: filterFc(res.data.obj.user),
                          },
                          true
                  );
                  clearScrollFn = clear;
                  clearScrollFc = clearScroll;
                  activeScroll = active;
                  updateShow = updateShowItem;
                  (userData.value as any).myList = result;
                  loading.value = false;
                })
                .catch((e) => {
                  console.log("err");
                  loading.value = false;
                });
      }
      /**
       * 获取我的【关注】
       */
      function getMyFollow() {
        loading.value = true;
        axios
            .get(`/M/Personal/MyFollowTopic/${id.value}`)
            .then((res) => {
              if (res.data.success) {
                focusUserData.value = null;
                res.data.obj.myList = res.data.obj.myList.map((item: any) => {
                  switch (item.mForumName) {
                    case "课堂":
                      return mapCourseData(item);
                    case "交流区":
                      return mapTopicData(item, res.data.obj.user);

                    default:
                      return item;
                  }
                });
                res.data.obj.userList = res.data.obj.follow;
                focusUserData.value = res.data.obj;
                // console.log("focusUserData.value",focusUserData.value);
              } else {
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
              loading.value = false;
            })
            .catch((e) => {
              console.log("err");
              loading.value = false;
            });
      }
      /**
       * 获取我的【粉丝】
       */
      function getFollowMy() {
        loading.value = true;
        axios
                .get(`/M/Personal/FollowMyTopic/${id.value}`)
                .then((res) => {
                  if (res.data.success) {
                    subUserData.value = null;
                    res.data.obj.myList = res.data.obj.myList.map((item: any) => {
                      switch (item.mForumName) {
                        case "课堂":
                          return mapCourseData(item);
                        case "交流区":
                          return mapTopicData(item, res.data.obj.user);

                        default:
                          return item;
                      }
                    });
                    res.data.obj.userList = res.data.obj.befollow;
                    subUserData.value = res.data.obj;
                    // console.log("subUserData.value",subUserData.value)
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                  loading.value = false;
                })
                .catch((e) => {
                  console.log("err");
                  loading.value = false;
                });
      }
      /**
       * 获取我的【私信】
       */
      function getMyLetter() {
        loading.value = true;
        axios
                .get(`/M/Personal/MyLetter/${id.value}`)
                .then((res) => {
                  if (res.data.success) {
                    letterUserData.value = null;
                    letterUserData.value = res.data.obj;
                    // console.log("letterUserData.value",letterUserData.value)
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                  loading.value = false;
                })
                .catch((e) => {
                  console.log(e);
                  loading.value = false;
                });
      }

      const userInfo = inject("userInfo") as any;
      const returnLoginUid = userInfo.id;
      const login = inject("handleHide") as () => void;
      const router = useRouter();
      function toLogin() {
        router.push({
          name: "Login",
          query: {
            redirect: router.currentRoute.value.fullPath,
          },
        });
      }
      /**
       * 按照type定位页面栏目焦点【我的】、【关注】、【粉丝】、【私信】
       */
      function getDataByType(type: string) {
        switch (type) {
          case "mine":
            currentMenu.value = "我的";
            break;
          case "follow":
            currentMenu.value = "关注";
            break;
          case "fans":
            currentMenu.value = "粉丝";
            break;
          case "letter":
            currentMenu.value = "私信";
            break;
          default:
            currentMenu.value = "我的";
        }
      }
      function changeMenu(item: string) {
        currentMenu.value = item;

        switch (item) {
          case "关注":
            updateShow(false);
            getDataByType("follow");
            break;
          case "粉丝":
            updateShow(false);
            getDataByType("fans");
            break;
          case "私信":
            updateShow(false);
            getDataByType("letter");
            break;
          default:
            updateShow(true);
            getDataByType("mine");
        }
      }

      function initData() {
        getPersonal();
        //  用户登录之后 且 是自己的个人中心才可以查看 关注、粉丝、私信等
        // if (localStorage.getItem("token") && id.value == userInfo.id) {
        //修改原因，页面刷新时会丢失userInfo对象，所以判断是登录的状态就往后端请求，具体判断在后端执行
        if (localStorage.getItem("token")) {
          getMyFollow();
          getFollowMy();
          getMyLetter();
        }
      }
      getDataByType(route.query.type as string);
      //  预览大图---start
      const previewing = ref(false);
      function closePreview() {
        previewing.value = false;
      }
      function showPreview() {
        previewing.value = true;
      }
      // 预览大图---end
      /**
       * 关注/取消关注
       * @param isFocus
       * @param id
       */
      function swichFocus(isFocus: boolean, id: string) {
        const url = isFocus ? "/M/BBSServer/Follow" : "/M/BBSServer/CancelFollow";
        axios
                .post(
                        url,
                        qs.stringify({
                          userid: id,
                        }),
                        {
                          params: {
                            validate: true,
                          },
                        }
                )
                .then((res) => {
                  if (res.data.success) {
                    if (isFocus) {
                      (userData.value as any).count = 1;
                    } else {
                      (userData.value as any).count = 0;
                    }
                    Toast({
                      type: "success",
                      title: res.data.msg,
                    });
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((e) => {
                  console.log("err");
                });
      }
      const isWriting = ref(false);
      const inputText = ref("");
      /**
       * 关闭发送私信的弹出层
       */
      function hideForm() {
        inputText.value = "";
        isWriting.value = false;
      }
      /**
       * 发送私信-登录判断
       */
      function handleFormShow() {
        if (userInfo.id) {
          isWriting.value = true;
        } else {
          Toast({
            type: "warning",
            title: "请先登录",
          });
          router.push({
            name: "Login",
          });
        }
      }
      /**
       * 提交私信
       */
      function submit() {
        if (!inputText.value) {
          Toast({
            type: "warning",
            title: "请先输入内容再提交哦~",
          });
          return false;
        }
        const data = qs.stringify({
          uid: userInfo.id,
          contents: inputText.value,
          reciveid: id.value,
        });
        axios
                .post("/M/Server/Letter", data)
                .then((res) => {
                  if (res.data.success) {
                    Toast({
                      type: "success",
                      title: res.data.msg,
                    });
                    hideForm();
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  // const message = err.message ? err.message : "未知错误，请联系管理员";
                  // Toast({
                  //   type: "error",
                  //   title: message,
                  // });
                });
      }
      const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
      function handleUserInfo(data: object) {
        UPDATE_USERINFO(data);
      }
      function updateToken(token: string) {
        localStorage.setItem("token", token);
      }
      wechatLogin(route.query.code as string, route.query.state as string)
              .then((res: any) => {
                if (!route.query.uid && typeof res === "object" && res.data.success) {
                  id.value = res.data.obj.id;
                  handleUserInfo(res.data.obj);
                }
                initData();
              })
              .catch((e) => {
                console.log(e, "error, -----------------------");
              });
      onActivated(() => {
        const uid = sessionStorage.getItem("personal-uid");
        if ((route.query.uid && uid && route.query.uid != uid) || !uid) {
          $(window).scrollTop(0);
          id.value = route.query.uid;
          initData();
          sessionStorage.setItem("personal-uid", route.query.uid as string);
        }
      });
      watch(
              [
                () => {
                  return route.query;
                },
                () => {
                  return route.name;
                },
              ],
              (to, from) => {
                //  只有个人中心跳个人中心的时候才会监控路由加载数据
                if (
                        (from[1] == "Personal" &&
                                (to[0] as any).uid &&
                                (to[1] as any) != "Attention" &&
                                (from[0] as any).uid != (to[0] as any).uid) ||
                        from[1] == "Message"
                ) {
                  updateShow(true);
                  clearScrollFc && clearScrollFc();
                  clearScrollFn && clearScrollFn();
                  id.value = (to[0] as any).uid as string;

                  isWriting.value = false;
                  getDataByType((to[0] as any).type as string);
                  loading.value = true;
                  initData();
                }
              }
      );
      //  页面被缓存时，停止加载更多页面
      onActivated(() => {
        activeScroll && activeScroll();
      });
      onDeactivated(() => {
        clearScrollFc && clearScrollFc();
      });
      onUnmounted(() => {
        clearScrollFc && clearScrollFc();
        clearScrollFn && clearScrollFn();
      });
      const loadOption = {
        text: "加载中...",
        color: "#df0024",
        textColor: "#df0024",
        modal: true,
      };
      return {
        id,
        userData,
        userInfo,
        toLogin,
        currentMenu,
        menu,
        changeMenu,
        dateFormat,
        swichFocus,
        loadOption,
        loading,
        showPreview,
        previewing,
        closePreview,
        isWriting,
        inputText,
        handleFormShow,
        hideForm,
        submit,
        focusUserData,
        subUserData,
        letterUserData,
        returnLoginUid
      };
    },
  });
