<template>
  <main v-if="userData">
    <div class="dynamic-wapper">
      <!--<div v-if="userData.user && userData.user.userIntroduce" class="dynamic-title">
        <p>{{ userData.user.userIntroduce }}</p>
      </div>-->
    </div>
    <div class="post-follow">
      <div class="post-like-user">
        <div>
          <div
            v-for="item in userData.contactsList"
            :key="item.id"
            class="post-like-list"
          >
            <!--消息发送人是本人，展示消息接收人-->
            <div v-if="item.uid == userData.myid">
              <router-link :to="{ name: 'Personal', query: { uid: item.receiveId } }">
                <span>
                  <img :src="item.receiveImg" alt="用户头像" />
                </span>
                <p>{{ item.receiveName }}</p>
              </router-link>
            </div>
            <div v-else>
              <router-link :to="{ name: 'Personal', query: { uid: item.uid } }">
                <span>
                  <img :src="item.userImg" alt="用户头像" />
                </span>
                <p>{{ item.userName }}</p>
              </router-link>
            </div>
          </div>
        </div>

        <div
          v-if="userData.contactsList && !userData.contactsList.length"
          class="post-like-list no-likes"
        >
          暂无私信
        </div>
      </div>
    </div>


    <div class="dynamic">
      <div class="post-wapper">
        <div
          class="letter_list_div"
          v-for="item in userData.contactsList"
          :key="item.id"
        >
          <div class="letter-list">
            <!--消息发送人是本人，展示消息接收人-->
            <div class="letter-pic" v-if="item.uid == userData.myid">
              <router-link :to="{ name: 'Personal', query: { uid: item.receiveId } }">
                <img :src="item.receiveImg" />
              </router-link>
            </div>
            <div class="letter-pic" v-else>
              <router-link :to="{ name: 'Personal', query: { uid: item.uid } }">
                <img :src="item.userImg" />
              </router-link>
            </div>

            <router-link :to="{ name: 'Chat', query: { id: item.id } }">
              <div class="letter-text">
                <div class="letter-h">{{ item.receiveName }}</div>
                <div class="letter-p">{{ item.contents }}</div>
              </div>
              <div class="letter-rr">
                <div class="letter-date">{{ dateFormat(item.createTime) }}</div>
              </div>
            </router-link>
          </div>
        </div>
        <empty
          text="动态"
          :showLive="false"
          v-if="userData.contactsList && !userData.contactsList.length"
        ></empty>
      </div>

      <div
        class="empty"
        v-if="userData.contactsList && !userData.contactsList.length"
        style="display: none"
      >
        <img src="@/assets/img/icon36.png" alt="" />
        暂无动态
      </div>
    </div>
  </main>
</template>


<script lang="ts">
import { defineComponent, ref, inject } from "vue";

import dateFormat from "@/utils/dateFormat";
import Empty from "@/components/common/Empty.vue";
export default defineComponent({
  name: "personalLetter",
  props: {
    userData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    Empty,
  },
  setup() {
    const userInfo = inject("userInfo") as any;

    return {
      userInfo,
      dateFormat,
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 50px;
}
.header {
  width: 100%;
  height: 211px;
  background: url("https://www.bzwz.com/static/m/images/bg.png") no-repeat
  center top;
  background-size: contain;
  padding-top: 41px;
  box-sizing: border-box;
  position: relative;
  /* border-bottom: 6px solid #f4f4f4; */
}

.user-info {
  color: #444;
  font-size: 14px;
  text-align: center;
}

.avatar {
  width: 86px;
  height: 86px;
  border: 2.5px solid #fff;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
}

.private-chat {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 15px;
  color: #fff;
  display: flex;
  align-items: center;
}

.private-chat img {
  width: 25px;
  height: 25px;
  margin-right: 3px;
  /* vertical-align: middle; */
}

.nick-name {
  margin-bottom: 12.5px;
}

.other-info {
  color: #666;
}

.other-info span {
  display: inline-block;
  margin: 0 8px;
}

.focus {
  border: none;
  outline: none;
  background-color: transparent;
  width: 72.5px;
  height: 27.5px;
  position: absolute;
  bottom: 50px;
  right: 10px;
  border-radius: 27.5px;
  color: #df0024;
  font-size: 12px;
  text-align: center;
  /* line-height: 27.5px; */
  display: flex;
  border: 1px solid #df0024;
  align-items: center;
  justify-content: center;
}

.focus img {
  width: 10px;
  height: 10px;
  margin-right: 3px;
  /* vertical-align: middle; */
}

.dynamic-wapper {
  border-top: 1px solid #f4f4f4;
}

.dynamic-title {
  padding-left: 20px;
  border-bottom: 6px solid #f4f4f4;
  box-sizing: border-box;
  /*height: 41px;*/
  /*line-height: 33.75px;*/
  position: relative;
  color: #444444;
  font-size: 16px;
  padding-right: 10px;
  padding-top: 7.5px;
}

.dynamic-title p {
  color: #999999;
  line-height: 22.5px;
  font-size: 15px;
  padding-bottom: 0.3rem;
  margin-top: 3.75px;
  word-break: break-all;
  padding-right: 0.1rem;
}

.dynamic-title::after {
  content: "";
  position: absolute;
  left: 10px;
  top: 22px;
  width: 1.5px;
  background-color: #df0024;
  height: 12.5px;
  transform: translateY(-50%);
}

/*关注个人*/

.post-follow-title {
  text-align: center;
  font-size: 15px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.post-follow-active a {
  border-bottom: 2px solid #df0024;
  color: #df0024;
}

.post-follow-before {
  position: relative;
}

.post-follow-before::before {
  content: "";
  display: block;
  position: absolute;
  left: -33.75px;
  top: 3.75px;
  width: 2.344px;
  height: 15px;
  background: #ccc;
}

.post-like-user {
  padding: 15px 20px 23px 20px;
  display: flex;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;

  height: 128px;

  overflow-x: auto;
}

.post-like-list {
  display: inline-block;
  width: 79.987px;
}

.post-like-list a {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.post-like-list span {
  display: block;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin: 0 auto;
}

.post-like-list span img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-like-list p {
  width: 100%;
  text-align: center;
  margin-top: 11.25px;
  color: #444444;
  font-size: 14px;
  line-height: 18.75px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*关注个人 end*/

.empty {
  color: #9f9f9f;
  font-size: 13px;
  padding-top: 79.987px;
  text-align: center;
}

.empty img {
  width: 132px;
  height: 103.988px;
  display: block;
  margin: 0 auto;

  margin-bottom: 15px;
}

.edit {
  position: fixed;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 80%;
  margin-top: -20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  z-index: 8;
}

.edit img {
  width: 100%;
  height: 100%;
  display: block;
}

.private-letter {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 133.988px;
  width: 100%;
  z-index: 10000;
  background-color: #fff;
  border-top: 1px solid #eee;
  padding: 0 10px;
  box-sizing: border-box;
}
.private-letter-operation {
  overflow: hidden;
  padding-top: 20px;
  font-size: 14px;
  color: #444444;
  box-sizing: border-box;
  margin-bottom: 4.988px;
}

.cancel {
  float: left;
}

.confirm {
  float: right;
  color: #df0024;
}

#letter-content {
  width: 100%;
  box-sizing: border-box;
  padding: 7.5px;
  color: #444444;
  font-size: 14px;
  height: 75px;
  background: #f4f4f4;
  border-radius: 4.988px;
  margin-top: 4.988px;
  border: none;
  outline: none;
  color: #444;
  font-size: 14px;
}

#letter-content::-webkit-input-placeholder {
  color: #444444;
  font-size: 14px;
}

.letter_list_div {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px 10px;
  border-bottom: 1px solid #eee;
}

.letter-pic {
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.letter-pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.letter-text {
  float: left;
  margin-left: 19px;
  width: 190px;
  padding-top: 4.988px;
}

.letter-h {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #444444;
  font-size: 15px;
}

.letter-p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #999999;
  font-size: 14px;
  margin-top: 10px;
}

.letter-rr {
  float: right;
  text-align: right;
  padding-top: 4.988px;
}

.letter-date {
  color: #666666;
  font-size: 14px;
}

.cancel-focus {
  border: none;
  outline: none;
  background-color: transparent;
  width: 75px;
  height: 27.5px;
  position: absolute;
  bottom: 50px;
  right: 10px;
  border-radius: 27.5px;
  color: #ccc;
  font-size: 12px;
  text-align: center;
  line-height: 27.5px;
  border: 1px solid #ccc;
  padding: 0;
}

.no-likes {
  font-size: 13px;
  text-align: center;
  margin: 0 auto;
  line-height: 128px;
  color: #333;
  text-align: center;
}

.form {
  border-top: 1px solid #eee;
  position: fixed;
  width: 100%;
  left: 0;
  height: 133px;
  bottom: 50px;
  background-color: #fff;
  padding: 7.5px 10px;
  box-sizing: border-box;
  z-index: 10;
}
.operation {
  height: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.operation div {
  float: left;
  width: 50%;
  font-size: 14px;
  color: #444444;
  box-sizing: border-box;
}

.operation .confirm {
  float: right;
  color: #df0024;
  text-align: right;
}

textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 7.5px;
  color: #444444;
  font-size: 14px;
  height: 75px;
  background: #f4f4f4;
  border-radius: 5px;
  margin-top: 5px;
  border: none;
  outline: none;
}
</style>
